.mainShopContainer{
   width: 100%;
   background-color: var(--white);
   cursor: default;
}

.headerCarousel{
   width: 100%;
   height: 740px;
   display: block;
   justify-content: center;
   align-content: flex-end;
   overflow: hidden;
}


.shop {
   width: 100%;
   margin: 15px auto;
   padding-top: 90px;
}
.shopSection, .cartSection, .shopSectionP{
   width: var(--body-w);
   margin: 0 auto;
   padding: 90px 0 78px;
}
.shopSectionP{
   padding-bottom: 48px;
}
.cartSection{
}

.bgLight{
   width: 100%;
   background-color: var(--light);
}
.bgLight50{
   width: 100%;
   background-color: var(--light-50);
}

.iconUp {
   position: fixed;
   right: 25px;
   bottom: 25px;
   cursor: pointer;
   z-index: 2;
   opacity: 0.5;
}

.iconUp:hover{
   opacity: 0.7;
}

@media (max-width: 768px) {
   .shopSection {
      width: 100%;
      padding: 75px 0 0 0;
      margin-top: 10px;
      /*background-image: url(./images/bg-icon-list.png);*/
   }
   .headerCarousel{
      display: none;
   }

}
