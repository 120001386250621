
.healthTechnology{
    width: 300px;
    height: 327px;
    position: absolute;
    left: 700px;
    top: 560px;
    opacity: 0.9;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
}
.healthTechnologyTitle{
    width: 240px;
    margin: 30px 30px 0 30px;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    color: var(--secondary);
    overflow: hidden;
}
.healthTechnologyBody{
    width: 240px;
    height: 100px;
    margin: 6px 30px 30px 30px;
    font-size: var(--bakery-fs5);
    line-height: 1.63;
    color: #1d2621;

}
.healthTechnologyButtonArea {
    height: 41px;
    display: flex;
    justify-content: right;
    position: absolute;
    bottom: 0;
    background-color: var(--white);
    width: 100%;
}

.healthTechnologyButton
{
    width: 80px;
    height: 20px;
    font-size: var(--bakery-fs6);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: right;
    color: var(--warning);
    margin: auto auto 14px 187px;
    overflow: hidden;
    cursor: pointer;
}

.healthTechnologyGo{
    margin: auto 15px 15px auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
}