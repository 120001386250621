.cont {
    max-width: var(--body-w);
    margin: 0 auto;
    padding-top: 175px;
    background-color: var(--light);
    @media (max-width: 768px) {
        padding-top: 100px;
    }
}

.title {
    width: 100%;
    height: 39px;
    margin-bottom: 31px;
    text-wrap: none;

    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    color: var(--dark);
}

@media (max-width: 768px) {
    .title {
        font-size: 30px;
        height: auto;
    }
}

.productRect {
    max-width: 730px;
    box-shadow: inset 0 0.5px 0 0 rgba(0, 0, 0, 0.3);
    display: block;
}

.textBlock {

}

.r1 {
    display: flex;
}

.c1 {
    width: 475px;
    height: 30px;
    margin: 1px auto 6px 0;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--dark);
}

.basketImg {
    width: 79px;
    height: 79px;
    margin: 1px 46px 0 0;
    object-fit: contain;
}

.gotoContainer {

}

.gotoButton {

}

.product {
    box-shadow: inset 0 0.5px 0 0 rgba(0, 0, 0, 0.3);
    display: flex;
    padding: 34px 0 10px;
}

.productTitle {
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--dark);
    display: flex;
    justify-content: space-between;
}

.productText3,
.productText2,
.productText {
    margin-top: 6px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.25;
    display: block;
}

.productText {
    padding-bottom: 23px;
}

.productText2 {
    margin-top: 23px;
    font-size: 12px;
}

.productText3 {
    margin-top: 11px;
    font-size: 12px;
}

.productInterval {
    width: 250px;

}

.productPrice {
    text-align: end;
    width: 70px;
}

.amountPrice {
    display: flex;
    width: 200px;
    justify-content: space-between;
}

.productAmount {
    width: 70px;
    display: flex;
    justify-content: space-between;
}

.productAmount1 {
    width: 20px;
    text-align: end;
}

.rectangle1 {
    width: 730px;
    box-shadow: inset 0 0.5px 0 0 rgba(0, 0, 0, 0.3);
}

.r11 {
    padding: 25px 0 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}

.r12 {
    margin-left: 20px;
    font-size: 14px;
    padding-bottom: 34px;
}

.r13 {
    padding-bottom: 15px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

.r14 {
    padding-bottom: 35px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: normal;
    display: flex;
    justify-content: space-between;
}

.ring {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: solid 6px var(--primary);
    background-color: var(--white);
}

.btns {
    box-shadow: inset 0 0.5px 0 0 rgba(0, 0, 0, 0.3);
    padding-top: 65px;
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;
}

.gotoButton, .goBack {
    width: 260px;
    height: 50px;
    font-size: var(--bakery-fs5);
    font-weight: 400;
    border-radius: 6px;
    background-color: var(--warning);
    color: var(--surface);
    display: flex;
    align-items: center;
    justify-content: center;
}

.gotoButton:hover {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(243, 107, 107, 0.5)), linear-gradient(to bottom, var(--warning), var(--warning));
}

.goBack {
    background-color: var(--muted);
    @media (max-width: 768px) {
        width: 150px;
    }
}

.gotoButton {
    margin: auto 0 auto auto;
    @media (max-width: 768px) {
        width: 150px;
    }
}

.goBack:hover {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(to bottom, var(--muted), var(--muted));
}


.col2 {
    display: flex;
    justify-content: flex-end;
}

.col2Rectangle {
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    /*position: sticky;*/
    position: fixed;
    top: 195px;
    @media (max-width: 768px) {
        display: none;
    }
}

.googleMap {
    width: 300px;
    height: 240px;
    margin-bottom: 24px;
    border-radius: 1% 1% 50% 50% / 1% 1% 12% 12%;
    border-color: black;
}

.c22 {
    padding-left: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--dark);
    font-size: var(--bakery-fs31);
}

.c221 {
    margin-bottom: 16px;
}

.c222 {
    margin-bottom: 7px;
    font-size: var(--bakery-fs5);
    font-weight: 600;
}

.c223 {
    margin-bottom: 7px;
    font-size: var(--bakery-fs5);
    color: #1d2621;
    text-wrap: nowrap;
}

.c224 {
    margin-bottom: 7px;
    font-size: var(--bakery-fs5);
    font-weight: 600;
}



