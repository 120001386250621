.container {
    margin-top: 0;
}

.header, .header2 {
    height: 150px;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--dark);
}

.header2, .header {
    height: 56px;
    margin-bottom: 12px;
}

.header2 {
    margin-top: 55px;
    margin-bottom: 12px;
}

.name,.nameD, .rect, .rectDate, .propSelected, .propCanSelect, .propCanNotSelect {
    height: 50px;
    font-size: var(--bakery-fs6);
    line-height: 1.4;
    letter-spacing: 0.16px;
    margin: auto 0;
    display: flex;
    align-items: center;
}


.name, .nameD, .rectDate {
    width: 126px;
    word-break: break-all;
}
.nameD{
    padding-top: 12px;
    display: block;
    /*margin-top: 5px;*/
}

.rect, .rectDate, .propSelected, .propCanSelect, .propCanNotSelect {
    width: 142px;
    padding: 0 9px;
    border-radius: 4px;
    border: solid 1px var(--primary);
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: var(--white);
    color: var(--dark)
}
.propSelected {
    border: solid 1px var(--primary);
    box-shadow: 0 0 3px 0 var(--primary);
    background-color: var(--white);
}
.propCanSelect {
    border: solid 1px var(--muted);
    box-shadow: 0 0 3px 0 var(--muted);
    background-color: var(--white);
    color: var(--dark);
}
.propCanNotSelect {
    border: solid 1px var(--muted);
    cursor: default;
    background-color: var(--white);
    color: var(--muted);
}
.propCanSelect:hover, .propSelected:hover{
    border: none;
    background-color: var(--primary-50);
    color: var(--dark);
}

/*/mt-0 px-0 d-flex*/
.selectorBlock {
    margin-top: 0;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 305px;
}


.column, .columnAmount {
    display: flex;
    align-items: center;
    padding: 0 5px;
}
.columnAmount{
    justify-content: center;
}

.timeInterval1, .timeInterval2 {
    height: 50px;
    width: 142px;
    border-radius: 4px;
    border: solid 1px var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    background-color: var(--white);
    font-size: var(--bakery-fs6);
    line-height: 1.4;
    letter-spacing: 0.16px;
}
.timeInterval2{
    width: 126px;
}

.timeInterval1.muted, .timeInterval2.muted {
    border: solid 1px var(--muted);
    color: var(--muted);
    cursor: default;
}
.timeInterval1.possible, .timeInterval2.possible {
    border: solid 1px var(--muted);
    box-shadow: 0 0 3px 0 var(--muted);
    color: var(--dark);
}
.timeInterval1.selected, .timeInterval2.selected {
    box-shadow: 0 0 3px 0 var(--primary);
    border: solid 1px var(--primary);
}

.timeInterval1.warning, .timeInterval2.warning {
    box-shadow: 0 0 3px 0 var(--warning);
    border: solid 1px var(--warning);
}
.timeInterval1.selected:hover,.timeInterval2.selected:hover{
    border: none;
    box-shadow: 0 0 3px 0 var(--primary-50);
    background-color: var(--primary-50);
    color: var(--dark);
}
.timeInterval1.possible:hover,.timeInterval2.possible:hover{
    border: none;
    background-color: var(--primary-50);
    color: var(--dark);
}


.time:focus {
    border: solid 1px var(--primary);
}

.selector, .selectorD, .selectorS {
    height: 62px;
    width: 142px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.selectorS {
    width: 126px;
    min-height: 74px;
}
.error {
    font-size: var(--bakery-fs6);
    color:var(--warning);
}

.amount {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: solid 1px var(--primary);
    border-radius: 4px;
    background-color: var(--white);
}

.selectorD > .amount, .selector > .amount {
    border: none;
}


.button-text-only-gray {
    margin: 1px 2px 1px 0px;
    padding: 2px 1px 2px 0px;
    border: gray;
    color: var(--text-variant);
    transition: none;
}

.btn_num, .btn_p, .btn_m, .btn_m_active {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--bakery-fs6);
    border-radius: 6px;
    border: none;
}

.btn_p {
    width: 50px;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 4px;
    cursor: pointer;
}
.btn_p:hover {
    border: none;
    box-shadow: 0 0 3px 0 var(--primary-50);
    background-color: var(--primary-50);
    color: var(--dark);
}

.btn_m, .btn_m_active {
    width: 50px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    color: var(--dark-60);
    cursor: pointer;
}
.btn_m_active:hover {
    border: none;
    box-shadow: 0 0 3px 0 var(--primary-50);
    background-color: var(--primary-50);
    color: var(--dark);
}

.dash,.dashActive{
    width: 20px;
    height: 2px;
    border-radius: 2.5px;
    background-color: #b9b9b9;
}
.dashActive{
    background-color: var(--dark);
}

.plus, .plus1{
    width: 20px;
    height: 2px;
    border-radius: 2.5px;
    background-color: var(--white);
    position: relative;
}
.plus1{
    transform: rotate(90deg);
    border-radius: 2.5px;
    position: absolute;
    top: 0;
    left:0;
}

.btn_num {
    width: 42px;
}

.btn_pm:hover {
    cursor: pointer;
}

.btn_pm:active {
    color: var(--warning);
}

.CombinedShape {
    width: 20px;
    height: 20px;
    margin: 5px 0 5px 6px;
    opacity: 0.6;
    border-radius: 16px;
    background-color: var(--warning);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--bakery-fs6);
    color: var(--white);
}
@media (max-width: 768px) {
    /*.selector, .selectorD, .selectorS,.timeInterval1, .timeInterval2 {
        width: 126px;
    }*/
    /*.selectorBlock {
        width: 200px;
    }*/
}


