.checkoutBasket{
    width: 300px;
    height: 720px;
    padding: 29px 15px 35px;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    position: relative;
}
.basket{
    width: 100%;
    max-height: 610px;
    overflow-y: auto;
}

.title{
    width: 173px;
    height: 50px;
    margin: 0 auto 11px 15px;
    font-size: 22px;
    line-height: 1.36;
    color: var(--dark);
}

.shadow{
    width: 100%;
    height: 2px;
    box-shadow: inset 0 -0.5px rgba(0, 0, 0, 0.3);
}

.cont{
    max-height: 430px;
    overflow: auto;
}

.basketRect {
    width: 100%;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0 -0.5px rgba(0, 0, 0, 0.3);
}
.text, .price{
    width: 132px;
    margin: 0 2px 3px 10px;
    font-size: var(--bakery-fs7);
    line-height: 1.5;
    color: var(--dark);
}
.price{
    width: 55px;
    justify-content: flex-end;
}
.basketImg {
    width: 56px;
    height: 57px;
    object-fit: cover;
}

.textBody2{
    width: 270px;
    padding: 14px 15px 16px;
}
.sp{
    display: flex;
    justify-content: space-between;
}

.sp > span{
    font-size: var(--bakery-fs7);
    color: var(--dark);
}

.footer{
}

.priceCont{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 25px 8px 25px;
    position: absolute;
    bottom: 0;
    box-shadow: inset 0 0.5px rgba(0, 0, 0, 0.3);

}
.priceCont span{
    font-size: var(--bakery-fs6);
    color: var(--dark);
}

.priceCont div{
    font-size: var(--bakery-fs21);
    color: var(--dark);
    line-height: 1.1;
}

@media (max-width: 768px) {
    .checkoutBasket{
        width: 100%;
        height: auto;
        padding: 29px 15px 35px;
        border-radius: 6px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--white);
        position: relative;
    }
    .priceCont{
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 25px 8px 0;
        position: relative;
        box-shadow: inset 0 0.5px rgba(0, 0, 0, 0.3);
    }
}