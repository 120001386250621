@import "./main.css";

:root {
    --secondary: #314b9f;
    --muted: #bdbdbd;
    --dark: #2c1902;
    --white: #fff;
    --warning: #f26363;
    --light: #fefbf8;
    --primary: #f2ab63;
    --danger: #ff0000;
    --light-50: #fffdfc;
    --primary-10: rgba(242, 171, 99, 0.1);
    --light-90: rgba(248, 246, 243, 0.9);
    --muted-30: rgba(189, 189, 189, 0.3);
    --white-50: rgba(255, 255, 255, 0.51);
    --warning-60: rgba(242, 99, 99, 0.6);
    --dark-60: rgba(44, 25, 2, 0.6);
    --dark-50: rgba(44, 25, 2, 0.5);
    --secondary-60: rgba(49, 75, 159, 0.6);
    --primary-5: rgba(242, 171, 99, 0.05);
    --primary-50: rgba(242, 171, 99, 0.5);
    --secondary-3: rgba(49, 75, 159, 0.03);

    --attribute: rgba(44, 25, 2, 0.7);
    --about: #1d2621;

    --aaaa: #d4d4d4;


    --text: var(--dark);/**/
    --text-variant: var(--warning);/**/
    --surface: var(--white);/**/
    --background: var(--light-50);/**/
    --background-header: var(--light);/**/


    --bakery-fs1: 50px;
    --bakery-fs2: 26px;
    --bakery-fs21: 28px;
    --bakery-fs3: 20px;
    --bakery-fs31: 22px;
    --bakery-fs4: 18px;
    --bakery-fs5: 16px;
    --bakery-fs6: 14px;
    --bakery-fs7: 12px;

    --header-h: 110px;
    --carousel-h: 740px;
    --body-w: 1110px;
    --logo-w: 132px;

}
@media (max-width: 1110px) {
    :root {
        --header-h: 60px;
        --body-w: 100%;
        --logo-w: 90px;
    }
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
    /*background-color: var(--light-50);*/
}



.btn-yes {
    width: 190px;
    height: 45px;
    margin: 0 20px 50px 10px;
    padding: 12px 70px 11px;
    border-radius: 4px;
    border: solid 1px var(--text);
    background-color: var(--warning);
    font-size: var(--bakery-fs6);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--surface);
}

.btn-no {
    width: 190px;
    height: 45px;
    margin: 0 0 50px 20px;
    padding: 13px 72px 12px;
    border-radius: 4px;
    border: solid 0.5px var(--text-variant);
    background-color: var(--background);
    font-size: var(--bakery-fs6);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: var(--text);
}

.form-control::placeholder {
    color: var(--text);
    opacity: 1;
}

.form-control.line {
    width: 795px;
    height: 55px;
    margin: 1px 2px 1px 0;
    padding: 2px 1px 2px 8px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    display: inline;
}

.form-control.Rectangle-s, .Rectangle-s {
    width: 190px;
    height: 55px;
    margin: 1px 2px 1px 10px;
    padding: 2px 1px 2px 8px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    display: inline;
}

.form-control.product-list-1 {
    background: transparent;
}

.form-control.Rectangle-m {
    width: 380px;
    height: 55px;
    margin: 1px 2px 1px 0;
    padding: 2px 1px 2px 8px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background-color: var(--surface);
}


.form-control.line:active, .form-control.line:focus, .form-control.line:hover, .form-control.line:focus-visible,
.form-control.Rectangle-s:active, .form-control.Rectangle-s:focus, .form-control.Rectangle-s:hover, .form-control.Rectangle-s:focus-visible,
.form-control.Rectangle-m:active, .form-control.Rectangle-m:focus, .form-control.Rectangle-m:hover, .form-control.Rectangle-m:focus-visible,
.Rectangle-m:active, .Rectangle-m:focus, .Rectangle-m:hover, .Rectangle-m:focus-visible {
    box-shadow: none;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    outline-color: var(--text-variant);
}

.form-control.product-list-1 {
    box-shadow: none;
    border: none;
    background: transparent;
    outline-color: transparent;
}

.form-control.product-list-1:active, .form-control.product-list-1:focus, .form-control.product-list-1:hover, .form-control.product-list-1:focus-visible {
    box-shadow: none;
    border: none;
    background: transparent;
    outline-color: transparent;
}


/* */
.button-text-only {
    margin: 1px 2px 1px 0;
    padding: 2px 1px 2px 0;
    border: none;
    background: transparent;
    color: var(--secondary);
    transition: none;
}

.button-text-only:hover, .button-text-only:focus, .button-text-only:active, .button-text-only:focus-visible {
    border: none;
    box-shadow: none;
    background: transparent;
    color: var(--secondary);
}

.button-text-only:disabled {
    background: transparent;
}

.button-selector {
    margin: 5px 0 5px 0;
    height: 55px;
    border: solid 1px var(--text-variant);
    background: transparent;
    color: var(--text);
    transition: none;
}

.button-selector_col {
    padding: 0 5px 0 5px;
}


.button-selector:hover, .button-selector:focus, .button-selector:active, .button-selector:focus-visible {
    border: none;
    box-shadow: none;
    background: transparent;
    color: var(--text);
}

.button-selector:disabled {
    background: transparent;
}

.color-text {
    color: var(--text)
}

.button-text-only-gray {
    margin: 1px 2px 1px 0;
    padding: 2px 1px 2px 0;
    border: none;
    background: transparent;
    color: var(--text-variant);
    transition: none;
}

.button-text-only-gray:disabled {
    background: transparent;
}

Button.btn-product-list-1 {
    border: none;
    background: transparent;
    color: var(--secondary);
}

.btn-product-list-1:hover, .btn-product-list-1:focus, .btn-product-list-1:active {
    border: none;
    box-shadow: none;
    background: var(--surface);
    color: var(--secondary);
    outline-color: transparent;
}

.selectType {
    width: 190px;
    height: 55px;
    margin: 1px 2px 0 1px;
    padding: 1px 1px 2px 10px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    color: var(--text);
    text-align: left;
}


.dropdown-toggle::after {
    all: initial;
}

.selectType:hover, .selectType:focus, .selectType:active, .selectType:focus-visible {
    box-shadow: none;
    border: solid 1px var(--text-variant);
    background: var(--surface);
    color: var(--text);
}

/*

.my-dropdown{
    display:inline;
    font-size: var(--bakery-fs6);
    letter-spacing: 0.16px;
    width: 50px;
    height: 55px;
    background-color: var(--surface);
}
.my-dropdown:hover,.my-dropdown:focus,.my-dropdown:active{
    background: var(--surface);
    background-color: var(--surface);
}
*/

img.Rectangle-Copy-4 {
    width: 190px;
    height: 55px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px var(--text-variant);
    background-color: var(--surface);
}

.my-span-4-image {
    color: var(--secondary);
    padding: 0;
}


/*+ ***********  btn from main.css   беда с активной кнопкой */

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
    color: var(--text);
    background-color: var(--surface);
    border-color: var(--text-variant);
}

.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

/* - ***********  btn from main.css -*/

.wrongBoundary {
    border-color: yellow;
}

.daysName {
    margin-left: -1em;
}

/* Navbar.Toggle */
.navbar-toggler:focus {
    border: none;
    box-shadow: none;
}



/* Carousel */
.myCarouselImg{
    width: 100vw;
    height: var(--carousel-h);
    object-fit: cover;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: left;
    padding: 0;
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: 12.5vw;
    list-style: none;
}
.carousel-indicators [data-bs-target] {

    width: 70px;
    height: 3px;

}
.carousel-indicators .active {
    opacity: 1;
    background-color: var(--warning);
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: none;
}



/* Shop sections */
.shopSectionName{
    height: 39px;
    margin-bottom: 40px;
    font-size: var(--bakery-fs1);
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.78;
    letter-spacing: normal;
    color: var(--dark);
}

@media (max-width: 768px) {
    .shopSectionName {
        font-size: var(--bakery-fs21);
        font-weight: 400;
        height: auto;
        /*display: flex;
        justify-content: center;*/
    }
}
