.basketCont{
    width: 310px;
}

.basket{
    width: 100%;
    display: block;
    margin-bottom: 10px;
    padding-top: 11px;
    overflow-x: hidden;
}

.gotoContainer{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-top: solid 15px var(--white);
    border-left: solid 25px var(--white);
    border-right: solid 25px var(--white);
    border-bottom: solid 40px var(--white);
    position: absolute;
    bottom: 0px;
}

.gotoButton {
    width: 230px;
    height: 50px;
    font-size: var(--bakery-fs5);
    font-weight: 400;
    border-radius: 4px;
    border: solid 1px var(--warning);
    background-color: var(--warning);
    color: var(--surface);
    display: flex;
    align-items: center;
    justify-content: center;
}

.gotoButton:hover {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(243, 107, 107, 0.5)), linear-gradient(to bottom, var(--warning), var(--warning));
}

.basketRect {
    padding: 24px 20px 23px 20px;
    display: inline-flex;
    background-color: var(--white);

}

.shadow{
    margin: 0 20px;
    height: 1px;
    box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.3);
}

.basketImg {
    min-width: 56px;
    height: 57px;
    object-fit: cover;
}

.basketProducts {
}

.textBlock{
    padding: 0 5px 0 10px;
    font-size: var(--bakery-fs7);
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);

}

.page {
    padding-bottom: 10px;
}

