.cont{
    width: var(--body-w);
    margin: 0 auto;
    padding-top: 175px ;
    background-color: var(--light);
}

.title{
    width: 100%;
    height: 39px;
    margin-bottom: 31px;
    text-wrap: none;

    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    color: var(--dark);
}
.cont1{
   /* max-width: 730px;*/
    padding-bottom: 40px;
    box-shadow: inset 0 0.5px 0 0 rgba(0, 0, 0, 0.3), inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.3);
}
.cont2{
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-end;
}
.textCont{
    width: 380px;

}
.text, .text2, .text3{
    padding: 31px 0 5px;
    font-size: var(--bakery-fs6);
    line-height: 1.43;
    color: var(--dark);
}
.text2{
    padding-top: 25px;
    max-width: 724px;
    height: auto;
    font-size: var(--bakery-fs7);
}
.text3{
    padding: 20px 0 1px;
    font-size: var(--bakery-fs7);
}
.text3 span{
    padding: 10px 0 5px;
    color: var(--secondary);
}

.dropDown{
    font-size: var(--bakery-fs6);
    color: var(--dark);
}

.input, .inputCountry , .inputPhone{
    width: 100%;
    height: 40px;
    border: solid 1px var(--muted);
    border-radius: 4px;
    background-color: var(--white);
    padding: 0 15px;
    display: flex;
    font-size: var(--bakery-fs6);
    line-height: 1.43;
    color: var(--dark);
}
.inputCountry{
    width: 95px;
    padding: 0 8px 0 8px;
    align-items: center;
}
.inputCountry>span{
    width: 90%;
    text-align: center;
}
.inputCountry>img{
    text-align: end;
}

.inputCountry:focus, .inputCountry:active{
    border: solid 1px var(--text);
    box-shadow: none;
    outline: none;
}

.inputPhone{
    width: 275px;
    margin: auto 0 auto 10px;
    padding: 0 8px 0 15px;
    align-items: center;
    justify-self: center;
}


.input:hover, .inputPhone:hover{
    box-shadow: 0 0 4px 0 rgba(189, 189, 189, 0.5);
    border: solid 1px var(--muted-30);
}

.input:active, .input:focus, .inputPhone:active, .inputPhone:focus{
    box-shadow: 0 0 4px 0 rgba(189, 189, 189, 0.5);
    border: solid 1px var(--muted-30);
    outline: none;
}

.input.error,.inputPhone.error {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px var(--danger);
}
.anotherCountry{
    align-self: center;
    justify-self: center;
    text-wrap: none;
    font-size: var(--bakery-fs7);
}

.emailErrTxt, .phoneErrTxt{
    height: 18px;
    margin-top: 5px;
    font-size: var(--bakery-fs7);
    line-height: 1.5;
    color: var(--danger);
}
.phoneErrTxt{
    padding-left: 105px;
}

.btns{
    display: flex;
    justify-content: space-between;
    margin: 65px  50px 0 0;
    padding-bottom: 65px;
}
.btns_sm{
    display: none;
}

.gotoButton, .goBack {
    width: 260px;
    height: 50px;
    font-size: var(--bakery-fs4);
    font-weight: 400;
    border-radius: 4px;
    border: solid 1px var(--warning);
    background-color: var(--warning);
    color: var(--surface);
    display: flex;
    align-items: center;
    justify-content: center;
}

.goBack{
    background-color: var(--muted);
    border: solid 1px var(--muted);
}

@media (max-width: 768px) {
    .goBack{
        width: 150px;
    }
    .gotoButton{
        width: 150px;

    }
    .btns_sm{
        display: flex;
        justify-content: space-between;
        margin-bottom: 65px;
    }
    .btns{
        display: none;
    }
    .textCont{
        width: 100%;
    }
    .cont{
        padding-top: 80px;
        width: 100%;
    }
}
@media (max-width: var(--body-w)) {
    .cont{
        margin-left: 16px;
        width: 100%;
    }
}
