
.headerContainer{
    width: 100%;
    height: var(--header-h);
    z-index: 1030;
    position: fixed;
    top:0;
    left: 0;
    @media (max-width: 768px) {

    }
}

.logoContainer {
    width: var(--logo-w);
    height: var(--header-h);
    display: flex;
    justify-content: left;
    background-color: transparent;
    z-index: 1031;
    position: fixed;
    top:0;
    left: 0;
    cursor: pointer;
    @media (max-width: 1110px) {
        margin-left: 5%
    }
}

.logo {
    width: var(--logo-w);
    height: 50px;
    margin: auto 0 20px;
    display: flex;
    justify-content: left;
    background-color: transparent;
    object-fit: contain;
    @media (max-width: 768px) {
    }
}

.navBar, .navBarP{
    width: 100%;
    height: var(--header-h);
    padding-top: 0;
    padding-bottom: 0;
}
.navBar{
    opacity: 0.94;
    background-color: var(--light);
    @media (max-width: 768px) {
        width: 100%;

    }

}

.navBarP{
    position: fixed;
    top:0;
    left: 0;
    z-index: 1031;
    background-color: transparent;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {


    }
}

.header{
    max-width: var(--body-w);
    height: var(--header-h);
    display: flex;
    justify-content: flex-end;
    padding-left: calc(var(--logo-w) + 15%);
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    @media (max-width: 768px) {
        padding-left: calc(var(--logo-w) + 15%);

    }
}

.navbarMenu {
    height: 25px;
    margin: auto 0 22px auto;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        height: 100%;
        margin: auto;

    }
}

.menuItem{
    text-wrap: none;
    margin-right: 10px;
    margin-left: 10px;
    font-size: var(--bakery-fs5);
    color: var(--dark);
    font-weight: normal;
    cursor: pointer;
    @media (max-width: 768px) {
        margin-right: 10px;
    }
}

.menuItem:hover{
    opacity: 0.4;
}

.menuItem:active, .menuItem:focus{
    font-weight: 600;
}

.basket {
    height: 53px;
    /*width: 100px;*/
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
    margin: auto 0 8px 8px;
    opacity: 1;
    @media (max-width: 1110px) {
        margin: auto 8px 16px;
    }
}


.navBar2 {
    height: 56px;
}

.amount {
    height: 24px;
    min-width: 36px;
    white-space: nowrap;
    margin: auto 5px 12px 2px;
    font-size: var(--bakery-fs6);
    overflow: visible;
    display: flex;
    justify-content: right;
    @media (max-width: 768px) {
        margin: auto 5px 10px 0;
    }
}

.cart {
    width: 22px;
    height: 24px;
    margin: auto 0 18px 2px;
    @media (max-width: 768px) {
        margin: auto 0 14px;
    }}
.cart:hover{
    opacity: 0.5;
}
