.img {
    /*border-bottom-right-radius: 100% 50%;
    border-bottom-left-radius: 100% 50%;*/


    border-radius: 1% 1% 50% 50% / 1% 1% 12% 12%;
    border: none;
    width: 100%;
    height: auto;
}

.card {
    width: 100%;
    height: 100%;
    cursor: default;
    /*position: relative;*/
}
@media (max-width: 768px) {
    .card {
      display: flex;
        justify-content: center;
        cursor: default;
        /*position: relative;*/
    }
}

.fixedCard {
    width: 300px;
    height: 610px;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    position: sticky;
    position: -webkit-sticky;
    top: 150px;
}


.imgContainer {
    border: none;
    background-color: transparent;
}


.productCard {
    margin: 4px 0 1rem;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: var(--background);
}

.body {
    margin-top: 25px;
    width: 260px;
    font-size: var(--bakery-fs6);
}

.price {
    /*"mt-5 px-2 w-100 d-flex justify-content-between"*/
    margin-top: 90px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    cursor: default;
}

.buttonGray, .buttonBlue, .buttonRed {
    width: 260px;
    height: 50px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--bakery-fs5);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    text-align: center;
    align-self: center;
    border-radius: 6px;
    border: none;
    background-color: var(--muted);
    color: var(--white);
    cursor: default;
}

.buttonBlue {
    background-color: var(--secondary)
}

.buttonRed:hover {
    cursor: pointer;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(243, 107, 107, 0.5)), linear-gradient(to bottom, var(--warning), var(--warning));
}


@media (max-width: 990px) {
    .buttonRed, .buttonBlue, .buttonGray{
        width: 150px;
        height: 45px;
        font-size: var(--bakery-fs6);
        font-weight: 300;
    }
    .card {
        width: 100%;
        height: 100%;
        cursor: default;
    }
}
