.cont{
    width: var(--body-w);
    height: 80vw;
    margin: 0 auto;
    padding-top: 200px ;
    background-color: var(--light);
}
.title{
    width: 100%;
    height: 39px;
    margin-bottom: 31px;
    text-wrap: none;

    font-size: 40px;
    font-weight: 300;
    line-height: 0.98;
    color: var(--dark);
}

.text{
    width: 725px;
    margin-top: 35px;
    padding-top: 35px;
    font-size: var(--bakery-fs6);
    line-height: 1.86;
    color: #1d2621;
    box-shadow: inset 0 0.5px 0 0 rgba(0, 0, 0, 0.3);
}
.text p{
    margin-bottom: 0;
}

.col2Rectangle{
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
}
.googleMap{
    width: 300px;
    height: 240px;
    margin: 0 auto 40px;
    padding-top: 24px;
    border-radius: 1% 1% 50% 50% / 1% 1% 12% 12%;
    border-color: black;
    display: flex;
    justify-content: center;
}


.c22{
    padding-left: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--dark);
    font-size: var(--bakery-fs31);
}
.c221{
    margin-bottom: 16px;
}
.c222{
    margin-bottom: 7px;
    font-size: var(--bakery-fs5);
    font-weight: 600;
}
.c223{
    margin-bottom: 7px;
    font-size: var(--bakery-fs5);
    color: #1d2621;
    text-wrap: none;

}
.c224{
    margin-bottom: 7px;
    font-size: var(--bakery-fs5);
    font-weight: 600;
}
@media (max-width: 768px) {
    .cont{
        width: auto;
        height: auto;
        margin: 0 auto;
        padding-top: 80px ;
        background-color: var(--light);
    }
    .title {
       display: flex;
        justify-content: center;
    }
    .text {
        padding-top: 24px;
        margin-top: 0;
    }
    .col2Rectangle{
        padding-top: 16px;
    }
}

