
.card, .cardAnimation {
    max-width: 350px;
    height: auto;
    position: relative;
    margin-bottom: 30px;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
}
/*.cardAnimation{
    max-width: 380px;
    animation: animation 1s infinite;
}
*/
.cardAnimation{
    transition: transform .2s;
    z-index: 2;
    transform: scale(1.2);
}

@keyframes animation {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.imgW {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.img, .imgAnimation {
    border-radius: 1% 1% 50% 50% / 1% 1% 12% 12%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    transition: transform .2s;
    transform: scale(1);
}
.imgAnimation {
    transition: transform .2s;
    z-index: 2;
    transform: scale(1.2);
}
.imgAnimationEnd {
    transition: transform .2s;
    transform: scale(1);
}

.info {
    overflow: hidden;
    height: auto;
    margin-bottom: 38px;
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 3s;
    animation-name: fadeIn;
    animation-duration: 3s
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.title, .body {
    font-size: var(--bakery-fs2);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    margin-left: auto;
    margin-right: auto;
    width: 88.6%;
    color: var(--dark);

}

.title {
    margin-top: 32px;
}

.body {
    height: auto;
    font-size: var(--bakery-fs6);
    font-weight: normal;
    margin-top: 15px;
    margin-bottom: 30px;
    overflow: hidden;
    color: var(--about);
}
.attributes{
    line-height: 1.71;
    color: var(--attribute);

}

.buttonArea {
    width: 100%;
    height: 50px;
    padding-right: 32px;
    display: flex;
    justify-content: right;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.buttonReserve, .inTheShope{
    width: auto;
    height: 20px;
    font-size: var(--bakery-fs6);
    font-weight: 500;
    line-height: 1.44;
    letter-spacing: 0.4px;
    text-wrap: none;
    background-color: transparent;
    color: #f26363;
    border: none;
    padding-right: 0;
    margin-right: 0;
    cursor: pointer;
}

.buttonReserve:focus, .buttonReserve.active {
    border: none;
    box-shadow: none;
    background-color: transparent;
    outline-color: transparent;
}

.buttonReserve:hover, .buttonReserve.active {
    color: #9d2626;

}
.inTheShope{
    color: var(--dark);
    cursor: default;
}

.IconGo {
    width: 22px;
    height: 22px;
    margin: 0 0 2px;
    object-fit: contain;
}
