.contact{
    display: flex;
    justify-content: space-between;
}

.name{
    width: 199px;
    height: 20px;
    margin: 0 auto 0 0;
    font-size: var(--bakery-fs6);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--dark);
}

.c1, .c2, .c3{
    width: 300px;
}

.c11{
    width: 147px;
    height: 20px;
    margin-bottom: 10px;
    font-size: var(--bakery-fs6);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--dark);
}
.c12, .c13, .c14, .c15, .c16{
    width: 100%;
    height: 26px;
    font-size: var(--bakery-fs6);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.16px;
    color: #1d2621;
    overflow: hidden;
    margin-bottom: 10px;
    text-wrap: nowrap;
}
.c12{
    line-height: 1;
    height: auto;}
.c13{
    margin-bottom: 40px;
}
.c14{
    font-weight: 600;
}

.c21, .c22, .msgText {
    width: 100%;
    height: 36px;
    margin: 5px 0;
    padding: 8px 0 10px 15px;
    font-size: var(--bakery-fs7);
    border-radius: 4px;
    border: solid 0.5px var(--dark);
    background-color: transparent;
}

.msgText{
    height: 84px;
    padding: 8px 5px  58px 15px;
    border-radius: 4px;
}

.c21:focus,.c21:active,.msgText:focus,.msgText:active
{
    outline-color: var(--text-variant);
}

.c24Button{
    width: 160px;
    height: 36px;
    margin-top: 8px;
    border-radius: 6px;
    background-color: var(--secondary);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.c24Messege{
    min-height: 40px;
    background-color: transparent;
    margin-top: 8px;
    color: var(--warning);
    font-size: var(--bakery-fs7);
}


.c3{
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .contact{
        flex-direction: column;
    }
    .c1, .c2, .c3{
        width: 100%;
    }
    .c11{
        width: 100%;
    }
    .c12, .c13, .c14, .c15, .c16{
        width: 100%;
    }
    .c21, .c22, .msgText {
        width: 100%;
    }
    .c2{
        margin-top: 20px;
    }
    .c3{
        justify-content: center;
    }
}
