.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1031; /*Sit on top under navbar */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow-y: auto; /* auto when Enable scroll  needed, if not - change on 'hidden'*/
    overflow-x: hidden;
    /*background-color: rgb(0, 0, 0);  Fallback color */
    /*background-color: rgba(0, 0, 0, 0.4);  Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s
}
/* Add Animation */
@-webkit-keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
}
@keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
}
/*-*/

/* Modal Content */
.modalContent {
    position: fixed;
    top: 0;
    left: 0;
    width: 310px;
    height: 710px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
    color: #000;
    overflow-x: hidden;
    overflow-y: hidden;
}

/* Add Animation */
@-webkit-keyframes slideIn {
    from {top:10px; opacity:0}
    to {top:10px; opacity:1}
}

@keyframes slideIn {
    from { opacity:0;}
    to { opacity:1;}
}
/*-*/

/* --Add arrow on top--*/
.arrowTop {
    margin-top: 40px;
}
 /* --Moved to MyModal.js*/
.modalContent.arrowTop:after {
    content: " ";
    position: absolute;
    left: 240px; /* */
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #fefbf8;
}

/* The Close Button */
.close {
    color: black;
    float: right;
    font-size: 24px;
    padding-right: 5px;
}

.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modalBody {
    /*padding: 2px 8px;*/
    height: 710px;
    overflow-y: auto;
}

