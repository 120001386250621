.calendarBox {
    display: block;
    width: 300px;
}

.month{
    display: flex;
    justify-content: center;
    font-size: var(--bakery-fs6);
    background-color: #faf7f4;
    padding: 4px 0 6px;
}

.week{
    text-align: center;
    font-size: var(--bakery-fs6);
    background-color: #faf7f4;
}

.table{
    width: 100%;
    margin: 0;
    font-size: var(--bakery-fs6);
}
.td {
    text-align: center;
    width: 40px;
    height: 38px;
    box-shadow: inset 0 0.5px 0 0 #b9b9b9;
    background-color: var(--white);
}

.footer{
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    box-shadow: inset 0 0.5px 0 0 #b9b9b9;
    background-color: #faf7f4;
}

.footer > div {
    margin: 11px 24px 9px 24px;
    cursor: pointer;
}
