.container {
}

.img {
    width: 140px;
    height: 140px;
    object-fit: contain;
    margin-top: 43px;
}

.lineR, .lineL {
    width: 920px;
    height: 1px;
    margin: 0 50px 15px auto;
    border-radius: 3px;
    background-color: #f2a663;
    overflow: hidden;
}

.lineL {
    margin: 0 auto 15px 50px;
}

.titleL, .titleR {
    font-size: var(--bakery-fs2);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--dark);
}

.titleL {
    margin: 42px auto 13px 65px;
}

.titleR {
    margin: 42px auto 13px 115px;
}

.articleL, .articleR {
    width: 790px;
    font-size: var(--bakery-fs6);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #1d2621;
}

.articleL {
    margin: 13px auto 0 65px;

}

.articleR {
    margin: 13px auto 0 115px;

}

