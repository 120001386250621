.footer {
    width: 100%;
    margin-top: 167px;
    opacity: 0.6;
    background-color: var(--dark);
}

.footerCont {
    max-width: var(--body-w);
    height: 201px;
    background-color: transparent;
    position: relative;
    top: 0;
    left: 0;
}

.c1 {
    height: 92px;
    padding-top: 47px;
    display: flex;
    justify-content: space-between;

}

.logo, .c12 {
    width: 132px;
    height: 45px;
    object-fit: contain;
}

.c12 {
    margin-left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fb,.instagram{
    width: 24px;
    height: 24px;
    margin: 0 0 0 20px;
}


.c2 {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}

.navbarMenu {
    white-space: nowrap;
    display: flex;
}

.navbarMenu > span {
    color: white;
    margin-right: 30px;
    cursor: pointer;
}

.cGDPR {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.cGDPR > span {
    color: white;
    font-size: 12px;
}
.c22 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.c22 > span {
    color: white;
}

@media (max-width: 768px) {
    .navbarMenu {
        display: none;
    }

    .footerCont {
        max-width: var(--body-w);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .c12 {
        display: none;
    }

    .c22 {
        padding-bottom: 32px;
    }
    .footer{
        margin-top: 16px;
    }
    .footerCont{
        height: 100%;
    }
    .c1{
        height: 50px;
        padding-top: 22px;
    }
    .c22{
        padding-bottom: 16px;
    }


}
