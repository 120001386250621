.container {
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
}

.column1 {
    max-width: 350px;
}

.column2 {
    max-width: 500px;
}

.column3 {
    max-width: 300px;
    display: flex;
}

.product, .productH, .productSelected {
    height: 56px;
    margin-left: 0;
    padding: 0 10px;
    opacity: 0.9;
    box-shadow: inset 0 -1px 0 0 rgba(203, 203, 203, 0.5);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-size: var(--bakery-fs5);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #b9b9b9;
}

.productSelected {
    box-shadow: inset 0 -1px 0 0 var(--primary);
    color: var(--dark);
}

.productH {
    font-size: 22px;
    padding: 0;
    letter-spacing: 0.05rem;

    color: var(--dark);
}

.product > span {
    cursor: pointer;
}

@media (max-width: 768px) {
    .column1 {
        width: auto;
    }
    .column3 {
        max-width: 100%;
        padding-bottom: 24px;
    }
}

